<template>
  <slot
    :data="$collection.state.value"
    :actions="$collection.actions"
    :mutations="$collection.mutations"
  />
</template>

<script lang="ts" setup>
import type { IColumns, IField } from '~/types/collection'

interface Emits {
  (event: 'loaded', value: boolean): void
}
interface Props {
  collection: string
  path: string
  query?: IField
  columns?: IColumns[]
  disableFetching?: boolean
}
const emit = defineEmits<Emits>()
const props = withDefaults(
  defineProps<Props>(),
  {
    query: {},
    disableFetching: false,
    columns: []
  }
)
const { collection, columns, path, query, disableFetching } = toRefs(props)

const $collection = useCollection(collection.value)

const watchedValues = computed(() => {
  return [...Object.values(query.value)].join()
})

onMounted(() => {
  $collection.actions.reset()

  if (columns.value.length) {
    $collection.mutations.setColumns(columns.value)
  }

  init()
})
watch(
  () => watchedValues.value,
  () => init()
)
watch(columns, () => {
  if (columns.value.length) {
    $collection.mutations.setColumns(columns.value)
  }
})

const init = async () => {
  $collection.mutations.setQuery(query.value)

  await fetchItems()
}
const fetchItems = async () => {
  if (disableFetching.value) {
    $collection.mutations.setLoading(false)
    return
  }

  emit('loaded', true)

  await $collection.actions.fetchItems(path.value)

  emit('loaded', false)
}
</script>
