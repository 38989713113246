import type { ICollection, IColumns, IMeta, IField } from '~/types/collection'

const toast = useToast()
const appConfig = useAppConfig()

export default (collection: string) => {
  const state = useState<ICollection>(`collection_${collection}`, () => ({
    loading: true,
    nextLoading: false,
    columns: [],
    selected: [],
    items: [],
    others: {},
    meta: {
      total: 0,
      take: +appConfig.DEFAULT_LIMIT,
      lastPage: 0,
      from: 0,
      to: 0,
      currentPage: 0,
      perPage: 0,
      prev: null,
      next: null
    },
    query: {}
  }))

  const actions = {
    setQuery ({ field, value }: IField) {
      if (field !== 'page' && field !== 'take') {
        this.resetPage()
        this.resetLimit()
      }

      mutations.setQueryValue({ field, value })
    },

    resetQuery () {
      mutations.setQuery({})
    },

    resetPage () {
      mutations.setQueryValue({
        field: 'page',
        value: 1
      })
    },

    resetLimit () {
      mutations.setQueryValue({
        field: 'take',
        value: +appConfig.DEFAULT_LIMIT
      })
    },

    resetItems () {
      mutations.setItems([])
      mutations.setOthers({})
      mutations.setMeta({
        total: 0,
        take: +appConfig.DEFAULT_LIMIT,
        lastPage: 0,
        from: 0,
        to: 0,
        currentPage: 0,
        perPage: 0,
        prev: null,
        next: null
      })
      this.resetLimit()
      this.resetPage()
    },

    async fetchItems (path: string) {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path,
        query: {
          ...state.value.query
        }
      })

      if (data) {
        const { items, others, meta }: { items: IField[], others: IField, meta: IMeta } = data

        mutations.setSelected([])
        mutations.setItems(items)
        mutations.setOthers(others)
        mutations.setMeta(meta)
      }
      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }

      mutations.setLoading(false)

      return [error, data]
    },

    async fetchNextItems (path: string) {
      mutations.setNextLoading(true)

      mutations.setQueryValue({
        field: 'page',
        value: state.value.query.page + 1
      })

      const [error, data] = await useFetchApi({
        path,
        query: {
          ...state.value.query
        }
      })

      if (data) {
        mutations.setSelected([])
        mutations.pushItems(data.items)
        mutations.setMeta(data.meta)
      }

      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }

      mutations.setNextLoading(false)

      return [error, data]
    },

    async getItem (path: string) {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path
      })

      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }

      mutations.setLoading(false)

      return [error, data]
    },

    async createItem (path: string, body?: IField) {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path,
        method: 'POST',
        body
      })

      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }

      mutations.setLoading(false)

      return [error, data]
    },

    async updateItem (path: string, body?: IField) {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path,
        method: 'PUT',
        body
      })

      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }

      mutations.setLoading(false)

      return [error, data]
    },

    async updatePositions (path: string, body?: IField) {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path,
        method: 'PUT',
        body
      })

      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }

      mutations.setLoading(false)

      return [error, data]
    },

    async removeItem (path: string, ids: number[]) {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path,
        method: 'DELETE',
        body: {
          ids
        }
      })

      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }

      mutations.setLoading(false)

      return [error, data]
    },

    reset () {
      this.resetItems()
      this.resetQuery()
    }
  }

  const mutations = {
    setLoading (loading: boolean) {
      state.value.loading = loading
    },

    setNextLoading (loading: boolean) {
      state.value.nextLoading = loading
    },

    setItems (items: IField[]) {
      state.value.items = items
    },

    setOthers (others: IField) {
      state.value.others = others
    },

    setMeta (meta: IMeta) {
      state.value.meta = meta
    },

    setColumns (columns: IColumns[]) {
      state.value.columns = columns
    },

    setSelected (selected: IField[]) {
      state.value.selected = selected
    },

    pushItems (items: IField[]) {
      state.value.items = [...state.value.items, ...items]
    },

    setQuery (query: IField) {
      state.value.query = query
    },

    setQueryValue ({ field, value }: IField) {
      state.value.query = {
        ...state.value.query,
        [field]: value
      }
    }
  }

  return {
    state,
    actions,
    mutations
  }
}
